<template>
    <section class="loading h_100">
        <ul style="padding-left: 0px;">
            <li>
                <div class="loader loader--style8" title="7">
                    <svg
                        version="1.1"
                        id="Layer_1"
                        fill="#FF2D55"
                        x="0px"
                        y="0px"
                        width="24px"
                        height="30px"
                        viewBox="0 0 24 30"
                        style="enable-background:new 0 0 50 50;"
                        xml:space="preserve"
                    >
                        <rect x="0" y="10" width="4" height="10" fill="#FF2D55" opacity="0.2">
                            <animate
                                attributeName="opacity"
                                attributeType="XML"
                                values="0.2; 1; .2"
                                begin="0s"
                                dur="0.6s"
                                repeatCount="indefinite"
                            />
                            <animate
                                attributeName="height"
                                attributeType="XML"
                                values="10; 20; 10"
                                begin="0s"
                                dur="0.6s"
                                repeatCount="indefinite"
                            />
                            <animate
                                attributeName="y"
                                attributeType="XML"
                                values="10; 5; 10"
                                begin="0s"
                                dur="0.6s"
                                repeatCount="indefinite"
                            />
                        </rect>
                        <rect x="8" y="10" width="4" height="10" fill="#FF2D55" opacity="0.2">
                            <animate
                                attributeName="opacity"
                                attributeType="XML"
                                values="0.2; 1; .2"
                                begin="0.15s"
                                dur="0.6s"
                                repeatCount="indefinite"
                            />
                            <animate
                                attributeName="height"
                                attributeType="XML"
                                values="10; 20; 10"
                                begin="0.15s"
                                dur="0.6s"
                                repeatCount="indefinite"
                            />
                            <animate
                                attributeName="y"
                                attributeType="XML"
                                values="10; 5; 10"
                                begin="0.15s"
                                dur="0.6s"
                                repeatCount="indefinite"
                            />
                        </rect>
                        <rect x="16" y="10" width="4" height="10" fill="#FF2D55" opacity="0.2">
                            <animate
                                attributeName="opacity"
                                attributeType="XML"
                                values="0.2; 1; .2"
                                begin="0.3s"
                                dur="0.6s"
                                repeatCount="indefinite"
                            />
                            <animate
                                attributeName="height"
                                attributeType="XML"
                                values="10; 20; 10"
                                begin="0.3s"
                                dur="0.6s"
                                repeatCount="indefinite"
                            />
                            <animate
                                attributeName="y"
                                attributeType="XML"
                                values="10; 5; 10"
                                begin="0.3s"
                                dur="0.6s"
                                repeatCount="indefinite"
                            />
                        </rect>
                    </svg>
                </div>
            </li>
            <li>
                <div class="loading_text">
                    <p v-if="channelStatus === 'change'">채널에 입장중입니다.</p>
                    <p v-else-if="channelStatus === 'restart'">
                        장시간 플레이어가 정지되어 새로운 플레이리스트를 불러오는 중입니다.
                    </p>
                    <p v-else-if="channelStatus === 'changecuration'">
                        {{ spacename }}에 어울리는 <br class="br" />
                        음악을 선곡중입니다.
                    </p>
                    <p v-else>플레이리스트를 불러오는 중 입니다.</p>
                </div>
            </li>
        </ul>
    </section>
</template>

<script>
export default {
  name: 'loadingBarPlayer',
  props: {
    channelStatus: String,
    spacename: {
      type: String,
      required: false,
      default: ''
    }
  }
};
</script>

<style scoped>
.loading {
  position: fixed;
  width: 100%;
  height: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 71;
}

.loading ul {
  width: 100%;
}

.loading ul li {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading_text {
  position: relative;
  width: 100%;
  padding: 0 5%;
}

.loading_text p {
  text-align: center;
  color: #fff;
  font-size: 16px;
  margin-bottom: 0px;
}

.br {
  display: none;
}

@media all and (max-width: 768px) {
  .br {
    display: inline-block;
  }
}
</style>
