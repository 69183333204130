<template>
    <div class="wrap">
        <channel-loading v-if="isLoading" :spacename="spaceInfo.spaceNm" :channelStatus="'changecuration'" />
        <PeriodAlertModal v-if="isPeriodAlertModal" @closeModal="isPeriodAlertModal = false" />
        <PeriodAlertModalRap v-if="isPeriodAlertModalRap" @closeModal="isPeriodAlertModalRap = false" />
        <PeriodAlertModalRapDomestic
            v-if="isPeriodAlertModalRapDomestic"
            @closeModal="isPeriodAlertModalRapDomestic = false"
        />
        <PeriodAlertModalElec v-if="isPeriodAlertModalElec" @closeModal="isPeriodAlertModalElec = false" />
        <PeriodAlertModalElec2 v-if="isPeriodAlertModalElec2" @closeModal="isPeriodAlertModalElec2 = false" />
        <PeriodAlertModalRapElec v-if="isPeriodAlertModalRapElec" @closeModal="isPeriodAlertModalRapElec = false" />
        <PeriodAlertModalNew v-if="isPeriodAlertModalNew" @closeModal="isPeriodAlertModalNew = false" />
        <PeriodAlertModalNew1980 v-if="isPeriodAlertModalNew1980" @closeModal="isPeriodAlertModalNew1980 = false" />
        <PeriodAlertModalRnb v-if="isPeriodAlertModalRnb" @closeModal="isPeriodAlertModalRnb = false" />
        <PeriodAlertModalRnbNew v-if="isPeriodAlertModalRnbNew" @closeModal="isPeriodAlertModalRnbNew = false" />
        <PeriodAlertModalJazz v-if="isPeriodAlertModalJazz" @closeModal="isPeriodAlertModalJazz = false" />
        <PeriodAlertModalCarol v-if="isPeriodAlertModalCarol" @closeModal="isPeriodAlertModalCarol = false" />
        <GenreAlertModal v-if="isGenreAlertModal" @closeModal="isGenreAlertModal = false" />
        <GenreSeasonAlertModal
            v-if="isGenreSeasonAlertModal"
            @closeModal="isGenreSeasonAlertModal = false"
            @closeModal2="setNotShowSeasonModalCarol"
        />
        <div class="space_pc">
            <section class="main myspace w_940">
                <div class="main_box">
                    <div class="main_box_title">
                        <h1 style="margin-bottom:0px;">큐레이션 설정</h1>
                        <div @click="clickPre()">
                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9.81055 7.39387L16.8109 0.393555L18.8105 2.39324L11.8102 9.39355L18.8105 16.3939L16.8109 18.3936L9.81055 11.3932L2.81023 18.3936L0.810547 16.3939L7.81086 9.39355L0.810547 2.39324L2.81023 0.393555L9.81055 7.39387Z"
                                    fill="white"
                                />
                            </svg>
                        </div>
                    </div>
                    <ul class="curation_tab_list">
                        <li class="curation_tab_item" @click="selectTab = 1" :class="{ on: selectTab === 1 }">기본설정</li>
                        <li class="curation_tab_item" @click="selectTab = 2" :class="{ on: selectTab === 2 }">세부설정</li>
                    </ul>
                    <div class="cs_main" id="tab1" v-if="selectTab === 1">
                        <div class="myspace_content qration">
                            <div class="three_type">
                                <div class="type_title">
                                    <div class="radio_box">
                                        <p style="margin-bottom:16px;cursor:default;">차량 이용목적</p>
                                    </div>
                                    <div class="type_span">
                                        <p :class="{ on: hasCarPurpose1 }" style="margin-bottom:0px;" @click="clickCarPurpose($event)">
                                            출근
                                            <input type="checkbox" v-model="curationInfo.carPurpose" value="5" hidden />
                                        </p>
                                        <p :class="{ on: hasCarPurpose2 }" style="margin-bottom:0px;" @click="clickCarPurpose($event)">
                                            퇴근
                                            <input type="checkbox" v-model="curationInfo.carPurpose" value="6" hidden />
                                        </p>
                                        <p :class="{ on: hasCarPurpose3 }" style="margin-bottom:0px;" @click="clickCarPurpose($event)">
                                            근거리 드라이브
                                            <input type="checkbox" v-model="curationInfo.carPurpose" value="7" hidden />
                                        </p>
                                        <p :class="{ on: hasCarPurpose4 }" style="margin-bottom:0px;" @click="clickCarPurpose($event)">
                                            장거리 운행
                                            <input type="checkbox" v-model="curationInfo.carPurpose" value="8" hidden />
                                        </p>
                                        <p :class="{ on: hasCarPurpose5 }" style="margin-bottom:0px;" @click="clickCarPurpose($event)">
                                            업무중
                                            <input type="checkbox" v-model="curationInfo.carPurpose" value="9" hidden />
                                        </p>
                                        <p :class="{ on: hasCarPurpose6 }" style="margin-bottom:0px;" @click="clickCarPurpose($event)">
                                            일상생활
                                            <input type="checkbox" v-model="curationInfo.carPurpose" value="10" hidden />
                                        </p>
                                        <p :class="{ on: hasCarPurpose7 }" style="margin-bottom:0px;" @click="clickCarPurpose($event)">
                                            여행
                                            <input type="checkbox" v-model="curationInfo.carPurpose" value="11" hidden />
                                        </p>
                                    </div>
                                </div>

                                <div class="type_title">
                                    <div class="radio_box">
                                        <p style="margin-bottom:0px;cursor:default;">국내곡 비율</p>
                                    </div>
                                    <div class="type_span">
                                        <span class="countDown" @click="countDown()" :class="{ disabled: disabledDown === true }">-</span>
                                        <p style="margin:0 8px;">국내 {{ curationInfo.domesticRate }}%</p>
                                        <span class="countUp" @click="countUp()" :class="{ disabled: disabledUp === true }">+</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cs_main" id="tab1" v-if="selectTab === 2">
                        <div class="myspace_content qration">
                            <update-curation-dim v-if="moreDetailYn !== 'Y'" @close-dim="onCloseDim" />
                            <div class="three_type">
                                <div class="type_title">
                                    <div class="radio_box">
                                        <p style="margin-bottom:0px;cursor:default;">선호 장르</p>
                                    </div>
                                    <div class="type_span">
                                        <p :class="{ on: curationInfo.genre.includes(1) }" value="1" @click="clickGenre($event)">
                                            발라드
                                            <input type="checkbox" v-model="curationInfo.genre" value="1" hidden />
                                        </p>
                                        <p :class="{ on: curationInfo.genre.includes(2) }" @click="clickGenre($event)">
                                            랩/힙합
                                            <input type="checkbox" v-model="curationInfo.genre" value="2" hidden />
                                        </p>
                                        <p :class="{ on: curationInfo.genre.includes(3) }" @click="clickGenre($event)">
                                            댄스
                                            <input type="checkbox" v-model="curationInfo.genre" value="3" hidden />
                                        </p>
                                        <p :class="{ on: curationInfo.genre.includes(4) }" @click="clickGenre($event)">
                                            R&B/Soul
                                            <input type="checkbox" v-model="curationInfo.genre" value="4" hidden />
                                        </p>
                                        <p :class="{ on: curationInfo.genre.includes(5) }" @click="clickGenre($event)">
                                            재즈
                                            <input type="checkbox" v-model="curationInfo.genre" value="5" hidden />
                                        </p>
                                        <p :class="{ on: curationInfo.genre.includes(6) }" @click="clickGenre($event)">
                                            일렉트로니카
                                            <input type="checkbox" v-model="curationInfo.genre" value="6" hidden />
                                        </p>
                                        <p :class="{ on: curationInfo.genre.includes(8) }" @click="clickGenre($event)">
                                            록/메탈
                                            <input type="checkbox" v-model="curationInfo.genre" value="8" hidden />
                                        </p>
                                        <p :class="{ on: curationInfo.genre.includes(9) }" @click="clickGenre($event)">
                                            포크/블루스/컨트리
                                            <input type="checkbox" v-model="curationInfo.genre" value="9" hidden />
                                        </p>
                                        <p
                                            :class="{ on: curationInfo.genre.includes(10), disabledTrot: curationInfo.domesticRate === '0' }"
                                            @click="clickGenre($event)"
                                        >
                                            트로트
                                            <input type="checkbox" v-model="curationInfo.genre" value="10" hidden />
                                        </p>
                                        <p :class="{ on: curationInfo.genre.includes(11) }" @click="clickGenre($event)">
                                            클래식
                                            <input type="checkbox" v-model="curationInfo.genre" value="11" hidden />
                                        </p>
                                        <p :class="{ on: curationInfo.genre.includes(12) }" @click="clickGenre($event)">
                                            뉴에이지
                                            <input type="checkbox" v-model="curationInfo.genre" value="12" hidden />
                                        </p>
                                    </div>
                                </div>
                                <div class="type_title">
                                    <div class="radio_box">
                                        <p style="margin-bottom:0px;cursor:default;">선호 시대</p>
                                    </div>
                                    <div class="type_span" :class="{ all_select: curationInfo.genre[0] === 13 }">
                                        <p
                                            :class="{ on: curationInfo.period.includes(1) }"
                                            style="margin-bottom:0px;"
                                            @click="clickPeriod($event)"
                                        >
                                            ~1980s
                                            <input type="checkbox" v-model="curationInfo.period" value="1" hidden />
                                        </p>
                                        <p
                                            :class="{ on: curationInfo.period.includes(2) }"
                                            style="margin-bottom:0px;"
                                            @click="clickPeriod($event)"
                                        >
                                            1990s
                                            <input type="checkbox" v-model="curationInfo.period" value="2" hidden />
                                        </p>
                                        <p
                                            :class="{ on: curationInfo.period.includes(3) }"
                                            style="margin-bottom:0px;"
                                            @click="clickPeriod($event)"
                                        >
                                            2000s
                                            <input type="checkbox" v-model="curationInfo.period" value="3" hidden />
                                        </p>
                                        <p
                                            :class="{ on: curationInfo.period.includes(4) }"
                                            style="margin-bottom:0px;"
                                            @click="clickPeriod($event)"
                                        >
                                            2010s
                                            <input type="checkbox" v-model="curationInfo.period" value="4" hidden />
                                        </p>
                                        <p
                                            :class="{ on: curationInfo.period.includes(5) }"
                                            style="margin-bottom:0px;"
                                            @click="clickPeriod($event)"
                                        >
                                            2020s
                                            <input type="checkbox" v-model="curationInfo.period" value="5" hidden />
                                        </p>
                                    </div>
                                </div>
                                <div class="type_title" v-if="!isApp">
                                    <div class="radio_box">
                                        <p style="margin-bottom:0px;cursor:default;">
                                            선호 음악 분위기<br />
                                            (3개 이상 선택)
                                        </p>
                                    </div>
                                    <div class="type_span" :class="{ all_select: curationInfo.genre[0] === 13 }">
                                        <p :class="{ on: curationInfo.emotion.includes(1) }" @click="clickEmotion($event)">
                                            차분한
                                            <input type="checkbox" v-model="curationInfo.emotion" value="1" hidden />
                                        </p>
                                        <p :class="{ on: curationInfo.emotion.includes(2) }" @click="clickEmotion($event)">
                                            편안한
                                            <input type="checkbox" v-model="curationInfo.emotion" value="2" hidden />
                                        </p>
                                        <p :class="{ on: curationInfo.emotion.includes(3) }" @click="clickEmotion($event)">
                                            활기찬
                                            <input type="checkbox" v-model="curationInfo.emotion" value="3" hidden />
                                        </p>
                                        <p :class="{ on: curationInfo.emotion.includes(4) }" @click="clickEmotion($event)">
                                            신나는
                                            <input type="checkbox" v-model="curationInfo.emotion" value="4" hidden />
                                        </p>
                                        <p :class="{ on: curationInfo.emotion.includes(5) }" @click="clickEmotion($event)">
                                            슬픈
                                            <input type="checkbox" v-model="curationInfo.emotion" value="5" hidden />
                                        </p>
                                        <p :class="{ on: curationInfo.emotion.includes(6) }" @click="clickEmotion($event)">
                                            쓸쓸한
                                            <input type="checkbox" v-model="curationInfo.emotion" value="6" hidden />
                                        </p>
                                        <p :class="{ on: curationInfo.emotion.includes(7) }" @click="clickEmotion($event)">
                                            독특한
                                            <input type="checkbox" v-model="curationInfo.emotion" value="7" hidden />
                                        </p>
                                        <p :class="{ on: curationInfo.emotion.includes(8) }" @click="clickEmotion($event)">
                                            파워풀한
                                            <input type="checkbox" v-model="curationInfo.emotion" value="8" hidden />
                                        </p>
                                        <p
                                            :class="{ on: curationInfo.emotion.includes(9) }"
                                            style="margin-bottom:0px;"
                                            @click="clickEmotion($event)"
                                        >
                                            흥분되는
                                            <input type="checkbox" v-model="curationInfo.emotion" value="9" hidden />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cs_main">
                        <div class="myspace_content qration">
                            <div class="three_type"></div>
                            <div class="form_bottom quation_btn">
                                <button
                                    style="text-align:center;"
                                    v-if="
                                        curationInfo.carPurpose.length > 0 &&
                                            curationInfo.genre.length > 0 &&
                                            curationInfo.period.length > 0 &&
                                            (isApp || (!isApp && curationInfo.emotion.length > 2)) &&
                                            !btnDisalbed
                                    "
                                    @click="checkCondition()"
                                >
                                    다음
                                </button>

                                <button v-else style="text-align: center; color: #5B5B5B; background: #4D1B24" disabled>다음</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <!-- 모바일 -->
        <div class="space_mo">
            <update-curation-dim :id="'mobiledim'" v-if="selectTab === 2 && moreDetailYn !== 'Y'" @close-dim="onCloseDim" />
            <div class="mo_space_header">
                <div class="mo_header_box">
                    <a>
                        <img src="/media/images/icon_back_24.svg" @click="clickPre()" />
                    </a>
                    <h2>큐레이션 설정</h2>
                    <div class="btn_close" @click="clickPre()">
                        <img src="/media/images/icon_close_24.svg" />
                    </div>
                </div>
            </div>
            <section class="main myspace">
                <div class="main_box">
                    <div class="cs_main">
                        <div class="myspace_content qration">
                            <ul class="curation_tab_list">
                                <li class="curation_tab_item" @click="selectTab = 1" :class="{ on: selectTab === 1 }">기본설정</li>
                                <li class="curation_tab_item" @click="selectTab = 2" :class="{ on: selectTab === 2 }">세부설정</li>
                            </ul>
                            <div class="types" v-if="selectTab === 1">
                                <div>
                                    <div class="radio_box">
                                        <p style="border:none;margin:0px;padding:0px;background:rgba(255, 255, 255, 0);cursor:default;">
                                            차량 이용목적
                                        </p>
                                    </div>
                                    <div>
                                        <p :class="{ on: hasCarPurpose1 }" @click="clickCarPurpose($event)">
                                            출근
                                            <input type="checkbox" v-model="curationInfo.carPurpose" value="5" hidden />
                                        </p>
                                        <p :class="{ on: hasCarPurpose2 }" @click="clickCarPurpose($event)">
                                            퇴근
                                            <input type="checkbox" v-model="curationInfo.carPurpose" value="6" hidden />
                                        </p>
                                        <p :class="{ on: hasCarPurpose3 }" @click="clickCarPurpose($event)">
                                            근거리 드라이브
                                            <input type="checkbox" v-model="curationInfo.carPurpose" value="7" hidden />
                                        </p>
                                        <p :class="{ on: hasCarPurpose4 }" @click="clickCarPurpose($event)">
                                            장거리 운행
                                            <input type="checkbox" v-model="curationInfo.carPurpose" value="8" hidden />
                                        </p>
                                        <p :class="{ on: hasCarPurpose5 }" @click="clickCarPurpose($event)">
                                            업무중
                                            <input type="checkbox" v-model="curationInfo.carPurpose" value="9" hidden />
                                        </p>
                                        <p :class="{ on: hasCarPurpose6 }" @click="clickCarPurpose($event)">
                                            일상생활
                                            <input type="checkbox" v-model="curationInfo.carPurpose" value="10" hidden />
                                        </p>
                                        <p :class="{ on: hasCarPurpose7 }" @click="clickCarPurpose($event)">
                                            여행
                                            <input type="checkbox" v-model="curationInfo.carPurpose" value="11" hidden />
                                        </p>
                                    </div>
                                </div>

                                <div>
                                    <div class="radio_box">
                                        <p style="border:none;margin:0px;padding:0px;background:rgba(255, 255, 255, 0);cursor:default;">
                                            국내곡 비율
                                        </p>
                                    </div>
                                    <div>
                                        <span class="countDown" @click="countDown()" :class="{ disabled: disabledDown === true }">-</span>
                                        <p style="margin:0 6px;">국내 {{ curationInfo.domesticRate }}%</p>
                                        <span class="countUp" @click="countUp()" :class="{ disabled: disabledUp === true }">+</span>
                                    </div>
                                </div>
                            </div>
                            <div class="types" v-if="selectTab === 2">
                                <div>
                                    <div class="radio_box">
                                        <p style="border:none;margin:0px;padding:0px;background:rgba(255, 255, 255, 0);cursor:default;">
                                            선호장르
                                        </p>
                                    </div>
                                    <div>
                                        <p :class="{ on: curationInfo.genre.includes(1) }" value="1" @click="clickGenre($event)">
                                            발라드
                                            <input type="checkbox" v-model="curationInfo.genre" value="1" hidden />
                                        </p>
                                        <p :class="{ on: curationInfo.genre.includes(2) }" @click="clickGenre($event)">
                                            랩/힙합
                                            <input type="checkbox" v-model="curationInfo.genre" value="2" hidden />
                                        </p>
                                        <p :class="{ on: curationInfo.genre.includes(3) }" @click="clickGenre($event)">
                                            댄스
                                            <input type="checkbox" v-model="curationInfo.genre" value="3" hidden />
                                        </p>
                                        <p :class="{ on: curationInfo.genre.includes(4) }" @click="clickGenre($event)">
                                            R&B/Soul
                                            <input type="checkbox" v-model="curationInfo.genre" value="4" hidden />
                                        </p>
                                        <p :class="{ on: curationInfo.genre.includes(5) }" @click="clickGenre($event)">
                                            재즈
                                            <input type="checkbox" v-model="curationInfo.genre" value="5" hidden />
                                        </p>
                                        <p :class="{ on: curationInfo.genre.includes(6) }" @click="clickGenre($event)">
                                            일렉트로니카
                                            <input type="checkbox" v-model="curationInfo.genre" value="6" hidden />
                                        </p>
                                        <p :class="{ on: curationInfo.genre.includes(8) }" @click="clickGenre($event)">
                                            록/메탈
                                            <input type="checkbox" v-model="curationInfo.genre" value="8" hidden />
                                        </p>
                                        <p :class="{ on: curationInfo.genre.includes(9) }" @click="clickGenre($event)">
                                            포크/블루스/컨트리
                                            <input type="checkbox" v-model="curationInfo.genre" value="9" hidden />
                                        </p>
                                        <p
                                            :class="{ on: curationInfo.genre.includes(10), disabledTrot: curationInfo.domesticRate === '0' }"
                                            @click="clickGenre($event)"
                                        >
                                            트로트
                                            <input type="checkbox" v-model="curationInfo.genre" value="10" hidden />
                                        </p>
                                        <p :class="{ on: curationInfo.genre.includes(11) }" @click="clickGenre($event)">
                                            클래식
                                            <input type="checkbox" v-model="curationInfo.genre" value="11" hidden />
                                        </p>
                                        <p :class="{ on: curationInfo.genre.includes(12) }" @click="clickGenre($event)">
                                            뉴에이지
                                            <input type="checkbox" v-model="curationInfo.genre" value="12" hidden />
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <div class="radio_box">
                                        <p style="border:none;margin:0px;padding:0px;background:rgba(255, 255, 255, 0);cursor:default;">
                                            선호시대
                                        </p>
                                    </div>
                                    <div :class="{ all_select: curationInfo.genre[0] === 13 }">
                                        <p :class="{ on: curationInfo.period.includes(1) }" @click="clickPeriod($event)">
                                            ~1980s
                                            <input type="checkbox" v-model="curationInfo.period" value="1" hidden />
                                        </p>
                                        <p :class="{ on: curationInfo.period.includes(2) }" @click="clickPeriod($event)">
                                            1990s
                                            <input type="checkbox" v-model="curationInfo.period" value="2" hidden />
                                        </p>
                                        <p :class="{ on: curationInfo.period.includes(3) }" @click="clickPeriod($event)">
                                            2000s
                                            <input type="checkbox" v-model="curationInfo.period" value="3" hidden />
                                        </p>
                                        <p :class="{ on: curationInfo.period.includes(4) }" @click="clickPeriod($event)">
                                            2010s
                                            <input type="checkbox" v-model="curationInfo.period" value="4" hidden />
                                        </p>
                                        <p :class="{ on: curationInfo.period.includes(5) }" @click="clickPeriod($event)">
                                            2020s
                                            <input type="checkbox" v-model="curationInfo.period" value="5" hidden />
                                        </p>
                                    </div>
                                </div>
                                <div v-if="!isApp">
                                    <div class="radio_box">
                                        <p style="border:none;margin:0px;padding:0px;background:rgba(255, 255, 255, 0);cursor:default;">
                                            선호 음악 분위기 (3개 이상 선택)
                                        </p>
                                    </div>
                                    <div>
                                        <p :class="{ on: curationInfo.emotion.includes(1) }" @click="clickEmotion($event)">
                                            차분한
                                            <input type="checkbox" v-model="curationInfo.emotion" value="1" hidden />
                                        </p>
                                        <p :class="{ on: curationInfo.emotion.includes(2) }" @click="clickEmotion($event)">
                                            편안한
                                            <input type="checkbox" v-model="curationInfo.emotion" value="2" hidden />
                                        </p>
                                        <p :class="{ on: curationInfo.emotion.includes(3) }" @click="clickEmotion($event)">
                                            활기찬
                                            <input type="checkbox" v-model="curationInfo.emotion" value="3" hidden />
                                        </p>
                                        <p :class="{ on: curationInfo.emotion.includes(4) }" @click="clickEmotion($event)">
                                            신나는
                                            <input type="checkbox" v-model="curationInfo.emotion" value="4" hidden />
                                        </p>
                                        <p :class="{ on: curationInfo.emotion.includes(5) }" @click="clickEmotion($event)">
                                            슬픈
                                            <input type="checkbox" v-model="curationInfo.emotion" value="5" hidden />
                                        </p>
                                        <p :class="{ on: curationInfo.emotion.includes(6) }" @click="clickEmotion($event)">
                                            쓸쓸한
                                            <input type="checkbox" v-model="curationInfo.emotion" value="6" hidden />
                                        </p>
                                        <p :class="{ on: curationInfo.emotion.includes(7) }" @click="clickEmotion($event)">
                                            독특한
                                            <input type="checkbox" v-model="curationInfo.emotion" value="7" hidden />
                                        </p>
                                        <p :class="{ on: curationInfo.emotion.includes(8) }" @click="clickEmotion($event)">
                                            파워풀한
                                            <input type="checkbox" v-model="curationInfo.emotion" value="8" hidden />
                                        </p>
                                        <p :class="{ on: curationInfo.emotion.includes(9) }" @click="clickEmotion($event)">
                                            흥분되는
                                            <input type="checkbox" v-model="curationInfo.emotion" value="9" hidden />
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="form_bottom">
                                <button
                                    style="text-align:center;"
                                    v-if="
                                        curationInfo.carPurpose.length > 0 &&
                                            curationInfo.genre.length > 0 &&
                                            curationInfo.period.length > 0 &&
                                            (isApp || (!isApp && curationInfo.emotion.length > 2)) &&
                                            !btnDisalbed
                                    "
                                    @click="checkCondition()"
                                >
                                    다음
                                </button>

                                <button v-else style="text-align: center; color: #5B5B5B; background: #4D1B24" disabled>다음</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';
import UpdateCurationDim from '@/components/other/UpdateCurationDim.vue';
import PeriodAlertModal from '@/components/modal/Space/PeriodAlertModal.vue';
import PeriodAlertModalRapElec from '@/components/modal/Space/PeriodAlertModalRapElec.vue';
import PeriodAlertModalRap from '@/components/modal/Space/PeriodAlertModalRap.vue';
import PeriodAlertModalRapDomestic from '@/components/modal/Space/PeriodAlertModalRapDomestic.vue';
import PeriodAlertModalElec from '@/components/modal/Space/PeriodAlertModalElec.vue';
import PeriodAlertModalElec2 from '@/components/modal/Space/PeriodAlertModalElec2.vue';
import PeriodAlertModalNew from '@/components/modal/Space/PeriodAlertModalNew.vue';
import PeriodAlertModalNew1980 from '@/components/modal/Space/PeriodAlertModalNew1980.vue';
import PeriodAlertModalRnb from '@/components/modal/Space/PeriodAlertModalRnb.vue';
import PeriodAlertModalRnbNew from '@/components/modal/Space/PeriodAlertModalRnbNew.vue';
import PeriodAlertModalJazz from '@/components/modal/Space/PeriodAlertModalJazz.vue';
import PeriodAlertModalCarol from '@/components/modal/Space/PeriodAlertModalCarol.vue';
import GenreAlertModal from '@/components/modal/Space/GenreAlertModal.vue';
import GenreSeasonAlertModal from '@/components/modal/Space/GenreSeasonAlertModal.vue';
import Loading from '@/components/modal/PlayerWS/common/Loading.vue';
import axios from 'axios';
import VueCookies from 'vue-cookies';
import $ from 'jquery';
import { isEqual } from 'lodash';
import { postRandomPreferMusic } from '@/service/api/profileApi';
import { updateCurationNotice } from '@/service/api/menuApi';
import { leaveChannel } from '@/service/api/webcastApi';
import { getCurationInfo } from '@/service/api/playerApi';
export default defineComponent({
  data () {
    return {
      spaceInfo: {},
      originalCurationInfo: {
        targetUnits: [],
        genre: [],
        period: [],
        emotion: []
      },
      moreDetailYn: '',
      spaceType: '',
      btnDisalbed: false,
      isApp: false,
      isPeriodAlertModal: false,
      isPeriodAlertModalRap: false,
      isPeriodAlertModalElec: false,
      isPeriodAlertModalElec2: false,
      isPeriodAlertModalRapElec: false,
      isPeriodAlertModalNew: false,
      isPeriodAlertModalNew1980: false,
      isPeriodAlertModalRapDomestic: false,
      isPeriodAlertModalRnb: false,
      isPeriodAlertModalJazz: false,
      isPeriodAlertModalRnbNew: false,
      isPeriodAlertModalCarol: false,
      isGenreAlertModal: false,
      isGenreSeasonAlertModal: false,
      notShowSeasonModalCarol: localStorage.getItem('notShowSeasonModalCarol'),
      disabledUp: false,
      disabledDown: false,
      curationTemp: {
        mood: [],
        target: [],
        unit: [],
        domesticRate: '',
        genre: [],
        period: [],
        carMood: [],
        carPurpose: [],
        emotion: []
      },
      selectItem: {
        mood: [],
        target: [],
        unit: [],
        genre: [],
        period: [],
        carMood: [],
        carPurpose: [],
        emotion: []
      },
      curationInfo: {
        mood: null,
        target: [],
        unit: [],
        domesticRate: '',
        genre: [],
        period: [],
        carMood: null,
        carPurpose: [],
        emotion: []
      },
      allList: {
        mood: ['모던', '럭셔리', '빈티지', '아기자기', '트렌디'],
        target: ['유아/아동', '청소년', '청년', '중장년', '노년'],
        unit: ['혼자', '친구', '연인', '가족', '동료'],
        genre: [
          '발라드',
          '랩/힙합',
          '댄스',
          'R&B/Soul',
          '재즈',
          '일렉트로니카',
          '인디음악',
          '록/메탈',
          '포크/블루스/컨트리',
          '트로트',
          '클래식',
          '뉴에이지',
          '캐롤'
        ],
        period: ['~1980', '1990', '2000', '2010', '2020'],
        carPurpose: ['출퇴근용', '일상생활', '업무용', '드라이브용'],
        carMood: ['차분한 카페', '럭셔리한 호텔', '신나는 클럽', '활기찬 헬스장', '힐링 스파샵'],
        emotion: ['차분한', '편안한', '활기찬', '신나는', '슬픈', '쓸쓸한', '독특한', '파워풀한', '흥분되는']
      },
      carPurposeList: [
        { key: '5', value: '출근' },
        { key: '6', value: '퇴근' },
        { key: '7', value: '근거리 드라이브' },
        { key: '8', value: '장거리 운행' },
        { key: '9', value: '업무중' },
        { key: '10', value: '일상생활' },
        { key: '11', value: '여행' }
      ],
      authorizedMail: [
        'playzone1911@gmail.com',
        'playzone1912@gmail.com',
        'playzone1913@gmail.com',
        'playzone1914@gmail.com',
        'playzone1915@gmail.com',
        'eddytime1911@gmail.com',
        'eddy@aplayz.co.kr',
        'cooler1993@naver.com',
        'psh_work@naver.com',
        'smtown@aplayz.co.kr',
        'smtown@hyundai.com',
        'dinasyure@gmail.com',
        'qmmp@naver.com',
        'qwas9885@gmail.com',
        'kwk@aplayz.co.kr',
        'chleorjs37@gmail.com',
        'aplayztest@gmail.com',
        'powfly@naver.com',
        'aplayz_coex01@gmail.com',
        'aplayz_coex02@gmail.com',
        'aplayz_coex03@gmail.com',
        'aplayz_coex04@gmail.com',
        'aplayz_coex05@gmail.com',
        'aplayz_coex06@gmail.com',
        'aplayz_coex07@gmail.com',
        'aplayztest_b@gmail.com'
      ],
      auth: false,
      selectTab: 1,
      isLoading: false
    };
  },
  components: {
    PeriodAlertModal,
    PeriodAlertModalElec,
    PeriodAlertModalElec2,
    PeriodAlertModalRap,
    PeriodAlertModalRapDomestic,
    PeriodAlertModalRapElec,
    PeriodAlertModalNew,
    PeriodAlertModalNew1980,
    PeriodAlertModalRnb,
    PeriodAlertModalRnbNew,
    PeriodAlertModalJazz,
    PeriodAlertModalCarol,
    GenreAlertModal,
    GenreSeasonAlertModal,
    'update-curation-dim': UpdateCurationDim,
    'channel-loading': Loading
  },
  mounted () {
    window.addEventListener('resize', this.resizeEvent);
  },
  methods: {
    async updateMoreDetail () {
      await axios
        .post('/api/profile/updateMoreDetail', {
          spaceId: localStorage.getItem('spaceId')
        })
        .then(res => {
          const { resultCd } = res.data;
          if (resultCd === '0000') {
            this.moreDetailYn = 'Y';
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    diffCuration () {
      this.curationInfo.period = this.curationInfo.period.map(item => parseInt(item)).sort((a, b) => a - b);
      this.curationInfo.genre = this.curationInfo.genre.map(item => parseInt(item)).sort((a, b) => a - b);
      this.curationInfo.emotion = this.curationInfo.emotion.map(item => parseInt(item)).sort((a, b) => a - b);
      const peroidEqual = isEqual(this.curationInfo.period, this.originalCurationInfo.period);
      const genreEqual = isEqual(this.curationInfo.genre, this.originalCurationInfo.genre);
      const emotionEqual = isEqual(this.curationInfo.emotion, this.originalCurationInfo.emotion);
      if (!peroidEqual || !genreEqual || !emotionEqual) {
        return true;
      }
      return false;
    },
    settingOriginalCuration () {
      this.originalCurationInfo.genre = this.curationInfo.genre.sort((a, b) => a - b);
      this.originalCurationInfo.period = this.curationInfo.period.sort((a, b) => a - b);
      this.originalCurationInfo.emotion = this.curationInfo.emotion.sort((a, b) => a - b);
    },
    async getMySpaceInfo () {
      const spaceId = localStorage.getItem('spaceId');
      const aToken = this.$cookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };
      return await axios
        .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
        .then(res => {
          return {
            success: true,
            data: res.data
          };
        })
        .catch(err => {
          return {
            success: false,
            data: err
          };
        });
    },
    onCloseDim () {
      this.moreDetailYn = 'Y';
      document.querySelector('#html').style.overflow = 'auto';
    },
    async resizeEvent () {
      const width = document.querySelector('#html').clientWidth;
      if (width < 500) {
        await this.$nextTick();
        if (this.selectTab === 2 && this.moreDetailYn !== 'Y') {
          document.querySelector('#html').style.overflow = 'hidden';
          if (document.querySelector('#mobiledim')) {
            document.querySelector('#mobiledim').style.height = `${document.querySelector('#html').clientHeight -
              86 -
              155}px`;
          }
        } else {
          document.querySelector('#html').style.overflow = 'auto';
          if (document.querySelector('#mobiledim')) {
            document.querySelector('#mobiledim').style.height = `100%`;
          }
        }
      }
    },
    checkApp () {
      // 앱, 설치형 플레이어 접근 체크 - 선호감정은 앱에서는 미노출 필요
      const userAgent = navigator.userAgent.toLowerCase();
      if (
        this.$store.state.installablePlayer ||
        userAgent.indexOf('aplayznativeaos') !== -1 ||
        userAgent.indexOf('aplayzaos') !== -1 ||
        userAgent.indexOf('aplayzios') !== -1
      ) {
        this.isApp = true;
      }
    },
    setNotShowSeasonModalCarol () {
      // 캐롤 시즌 모달
      this.notShowSeasonModalCarol = 'Y';
      this.isGenreSeasonAlertModal = false;
    },
    checkCondition () {
      // 큐레이션 선택값 체크
      const periodNo = this.curationInfo.period.toString();
      if (this.curationInfo.domesticRate === '0' && this.curationInfo.genre.length === 1) {
        // 해외100% 장르 선택값이 1개인 경우
        if (this.curationInfo.genre[0] === 2 && periodNo === '1') {
          // 장르가 랩/힙합이고 시대가 1980s 인 경우
          this.isPeriodAlertModalRap = true;
        } else if (this.curationInfo.genre[0] === 6) {
          // 장르가 일렉트로니카인 경우
          // 시대가 1980s, 1990s, 1980s/1990s, 1990s/1980s 인 경우 안내창 오픈
          periodNo === '1' || periodNo === '2' || periodNo === '1,2' || periodNo === '2,1'
            ? (this.isPeriodAlertModalElec = true)
            : this.updateCuration();
        } else if (this.curationInfo.genre[0] === 12 && this.curationInfo.period.length < 3) {
          this.isPeriodAlertModalNew = true;
        } else {
          this.updateCuration();
        }
      } else if (this.curationInfo.domesticRate === '0' && this.curationInfo.genre.length === 2) {
        // 해외100% 장르 선택값이 2개인 경우
        const check = this.curationInfo.genre.includes(2 || 6); // 장르 선택값이 랩/힙합, 일렉트로니카 인 경우 true
        check && periodNo === '1' ? (this.isPeriodAlertModalRapElec = true) : this.updateCuration(); // 시대가 1980s인 경우 안내창 오픈
      } else if (this.curationInfo.domesticRate === '100' && this.curationInfo.genre.length === 1) {
        // 국내100% 장르 선택값이 1개인 경우
        this.curationInfo.genre[0] === 4 && periodNo === '1' ? (this.isPeriodAlertModal = true) : this.updateCuration(); // 선택한 장르가 R&B/Soul이고 시대가 1980s인 경우 안내창 오픈
      } else if (this.curationInfo.domesticRate === '80' && this.curationInfo.genre.length === 1) {
        // 국내 80% 이고 선택장르가 1개인 경우
        if (this.curationInfo.genre[0] === 4 && periodNo === '1') {
          // 장르가 R&B/Soul이고 시대가 1980s 인 경우
          this.isPeriodAlertModalRnb = true;
        } else if (this.curationInfo.genre[0] === 6) {
          // 장르가 일렉트로니카인 경우
          // 시대가 1980s, 1990s, 1980s/1990s, 1990s/1980s 인 경우 안내창 오픈
          periodNo === '1' || periodNo === '2' || periodNo === '1,2' || periodNo === '2,1'
            ? (this.isPeriodAlertModalElec2 = true)
            : this.updateCuration();
        } else if (this.curationInfo.genre[0] === 5 && periodNo === '1') {
          // 장르가 재즈이고 시대가 1980s 인 경우
          this.isPeriodAlertModalJazz = true;
        } else if (this.curationInfo.genre[0] === 2 && periodNo === '1') {
          // 장르가 랩/힙합이고 시대가 1980s 인 경우
          this.isPeriodAlertModalRapDomestic = true;
        } else if (this.curationInfo.genre[0] === 12) {
          // 선택한 장르가 뉴에이지인 경우
          periodNo === '1' || periodNo === '2' || periodNo === '1,2' || periodNo === '2,1'
            ? (this.isPeriodAlertModalNew1980 = true)
            : this.updateCuration();
        } else {
          this.updateCuration();
        }
      } else if (this.curationInfo.domesticRate === '80' && this.curationInfo.genre.length === 2) {
        const checkGenre = this.curationInfo.genre.includes(4 || 12); // 장르 선택값이 R&B/Soul, 뉴에이지 인 경우 true
        checkGenre && periodNo === '1' ? (this.isPeriodAlertModalRnbNew = true) : this.updateCuration(); // 시대가 1980s인 경우 안내창 오픈
      } else if (this.curationInfo.domesticRate !== '0' && this.curationInfo.genre.length === 1) {
        // 해외100%가 아니고 선택한 장르가 1개인 경우
        if (this.curationInfo.genre[0] === 12) {
          // 선택한 장르가 뉴에이지인 경우
          periodNo === '1' || periodNo === '2' || periodNo === '1,2' || periodNo === '2,1'
            ? (this.isPeriodAlertModalNew1980 = true)
            : this.updateCuration();
        } else if (this.curationInfo.genre[0] === 2 && periodNo === '1') {
          // 선택한 장르가 랩/힙합이고 선택한 시대가 1980s인 경우
          this.isPeriodAlertModalRapDomestic = true;
        } else if (this.curationInfo.genre[0] === 6) {
          // 장르가 일렉트로니카인 경우
          // 시대가 1980s, 1990s, 1980s/1990s, 1990s/1980s 인 경우 안내창 오픈
          periodNo === '1' || periodNo === '2' || periodNo === '1,2' || periodNo === '2,1'
            ? (this.isPeriodAlertModalElec2 = true)
            : this.updateCuration();
        } else {
          this.updateCuration();
        }
      } else {
        this.updateCuration();
      }
    },
    countUp () {
      // 국내 비율 +
      this.disabledDown = false;
      if (parseInt(this.curationInfo.domesticRate) < 0) {
        this.curationInfo.domesticRate = '0';
      } else if (parseInt(this.curationInfo.domesticRate) >= 80) {
        this.curationInfo.domesticRate = '80';
        this.disabledUp = true;
      } else {
        this.disabledUp = false;
        const domesticRate = parseInt(this.curationInfo.domesticRate) + 10;
        this.curationInfo.domesticRate = domesticRate.toString();
      }
    },
    countDown () {
      // 국내 비율 -
      this.disabledUp = false;
      if (parseInt(this.curationInfo.domesticRate) <= 10) {
        // 국내 비율이 10%에서 0%로 바뀔때
        // var domesticRate = parseInt(this.curationInfo.domesticRate) - 10;
        // this.curationInfo.domesticRate = domesticRate.toString();
        this.curationInfo.domesticRate = '0';
        if (this.curationInfo.genre.includes(10)) {
          // 장르에 트로트가 포함된 경우 삭제
          this.curationInfo.genre = this.curationInfo.genre.filter(el => el !== 10);
        }
        this.disabledDown = true;
      } else if (parseInt(this.curationInfo.domesticRate) > 80) {
        this.curationInfo.domesticRate = '80';
      } else {
        this.disabledDown = false;
        const domesticRate = parseInt(this.curationInfo.domesticRate) - 10;
        this.curationInfo.domesticRate = domesticRate.toString();
      }
    },
    clickCarMood (e) {
      // 차량 내 선호분위기 클릭
      $(e.currentTarget.parentElement)
        .children('p')
        .removeClass('on');
      $(e.currentTarget).toggleClass('on');
      this.curationInfo.carMood = e.currentTarget.querySelector('input').value;
    },
    clickCarPurpose (e) {
      // 차량 이용목적 클릭
      $(e.currentTarget).toggleClass('on');
      const carPurposeList = this.curationInfo.carPurpose;
      const carPurpose = e.currentTarget.querySelector('input').value;

      const found = carPurposeList.find(el => el === carPurpose);
      if (found != null) {
        // 선택한 값이 있을때
        const filtered = carPurposeList.filter(el => el !== carPurpose);
        this.curationInfo.carPurpose = filtered;
      } else {
        // 선택한 값이 없을때
        this.curationInfo.carPurpose = [carPurpose];
      }
    },
    clickGenre (e) {
      // 선호 장르 클릭
      const genreList = this.curationInfo.genre;
      const genre = parseInt(e.currentTarget.querySelector('input').value);
      if (this.curationInfo.domesticRate === '0' && genre === 10) {
        this.isGenreAlertModal = true;
      } else {
        const index = genreList.findIndex(el => el === 13);
        if (genre === 13) {
          if (index === -1) {
            this.curationInfo.genre = [genre];
            $(e.currentTarget)
              .parent()
              .children('p')
              .removeClass('on');
            if (this.notShowSeasonModalCarol !== 'Y') this.isGenreSeasonAlertModal = true;
          } else {
            this.curationInfo.genre.splice(index, 1);
          }
        } else {
          if (index !== -1) {
            this.curationInfo.genre.splice(index, 1);
            $('.xmas_tag')
              .parent()
              .removeClass('on');
          }
          const found = genreList.find(el => el === genre);
          if (found != null) {
            // 선택한 값이 있을때
            const filtered = genreList.filter(el => el !== genre);
            this.curationInfo.genre = filtered;
          } else {
            // 선택한 값이 없을때
            this.curationInfo.genre.push(genre);
          }
        }

        $(e.currentTarget).toggleClass('on');
      }
    },
    clickPeriod (e) {
      // 선호 시대 클릭
      if (this.curationInfo.genre[0] === 13) {
        this.isPeriodAlertModalCarol = true;
      } else {
        $(e.currentTarget).toggleClass('on');
        const periodList = this.curationInfo.period;
        const period = parseInt(e.currentTarget.querySelector('input').value);

        const found = periodList.find(el => el === period);
        if (found != null) {
          // 선택한 값이 있을때
          const filtered = periodList.filter(el => el !== period);
          this.curationInfo.period = filtered;
        } else {
          // 선택한 값이 없을때
          this.curationInfo.period.push(period);
        }
      }
    },
    clickEmotion (e) {
      // 선호 감정 클릭
      $(e.currentTarget).toggleClass('on');
      const emotionList = this.curationInfo.emotion;
      const emotion = parseInt(e.currentTarget.querySelector('input').value);

      const found = emotionList.find(el => el === emotion);
      if (found != null) {
        // 선택한 값이 있을때
        const filtered = emotionList.filter(el => el !== emotion);
        this.curationInfo.emotion = filtered;
      } else {
        // 선택한 값이 없을때
        this.curationInfo.emotion.push(emotion);
      }
    },
    selectCarMood () {
      const carMoodList = this.allList.carMood;
      for (const i in carMoodList) {
        this.selectItem.carMood[i] = this.curationTemp.carMood.indexOf(carMoodList[i]) > -1;
      }
      const filter = carMoodList.indexOf(this.curationTemp.carMood) + 1;
      if (filter > 0) {
        this.curationInfo.carMood = filter;
      }
    },
    selectCarPurpose () {
      this.curationTemp.carPurpose.forEach(carPurPoseItem => {
        const find = this.carPurposeList.find(item => item.value === carPurPoseItem);

        if (find) {
          const key = find.key;
          if (!this.curationInfo.carPurpose.includes(key)) {
            this.curationInfo.carPurpose.push(key.toString());
          }
        }
      });
    },
    selectGenre () {
      const genreList = this.allList.genre;
      for (const i in genreList) {
        this.selectItem.genre[i] = this.curationTemp.genre.indexOf(genreList[i]) > -1;
        const filter = genreList.indexOf(this.curationTemp.genre[i]) + 1;
        if (filter > 0) {
          this.curationInfo.genre.push(filter);
        }
      }
    },
    selectPeriod () {
      const periodList = this.allList.period;

      for (const i in periodList) {
        this.selectItem.period[i] = this.curationTemp.period.indexOf(periodList[i]) > -1;
        const filter = periodList.indexOf(this.curationTemp.period[i]) + 1;
        if (filter > 0) {
          this.curationInfo.period.push(filter);
        }
      }
    },
    selectEmotion () {
      const emotionList = this.allList.emotion;
      for (const i in emotionList) {
        this.selectItem.emotion[i] = this.curationTemp.emotion.indexOf(emotionList[i]) > -1;
        const filter = emotionList.indexOf(this.curationTemp.emotion[i]) + 1;
        if (filter > 0) {
          this.curationInfo.emotion.push(filter);
        }
      }
    },
    async getCurationInfo () {
      const spaceId = localStorage.getItem('spaceId');
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };

      await axios
        .post('/api/profile/getCurationInfo', { spaceId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            const result = res.data.result;
            result.genre = result.genre.filter(genre => genre !== '인디음악'); // 인디음악을 선택한 경우 인디음악 제거

            setTimeout(() => {
              this.curationTemp.mood = result.mood;
              this.curationTemp.target = result.target;
              this.curationTemp.unit = result.targetUnit;
              this.curationTemp.domesticRate = result.domesticRate;
              this.curationInfo.domesticRate = result.domesticRate;
              this.curationTemp.genre = result.genre;
              this.curationTemp.period = result.period;
              this.curationTemp.carMood = result.carMood;
              this.curationTemp.carPurpose = result.carPurpose;
              this.curationTemp.emotion = result.emotion;

              // this.selectCarMood();
              this.selectCarPurpose();
              this.selectGenre();
              this.selectPeriod();
              this.selectEmotion();
              this.settingOriginalCuration();
            }, 500);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    settingCurationValue () {
      let genreArray = [];
      let meotionArray = [];
      this.curationInfo.carPurpose.forEach(val => {
        const item = this.$store.getters['player/getCarGenreFilter'](parseInt(val));
        const emotionItem = this.$store.getters['player/getCarEmotionFilter'](parseInt(val));
        genreArray = [...genreArray, ...item];
        meotionArray = [...meotionArray, ...emotionItem];
      });
      const genreSet = new Set(genreArray);
      const emotionSet = new Set(meotionArray);
      this.curationInfo.genre = Array.from(genreSet).sort((a, b) => a - b);
      this.curationInfo.emotion = Array.from(emotionSet).sort((a, b) => a - b);
      if (this.curationInfo.carPurpose.length > 1) {
        const sort = this.curationInfo.carPurpose.sort((a, b) => a - b);
        this.curationInfo.carMood = this.$store.getters['player/getCarMoodFilter'](parseInt(sort[0]));
      } else {
        this.curationInfo.carMood = this.$store.getters['player/getCarMoodFilter'](
          parseInt(this.curationInfo.carPurpose[0])
        );
      }
    },
    async updateCuration () {
      const resultDiff = this.diffCuration();
      this.isLoading = true;
      if (resultDiff && this.spaceInfo.moreDetailYn !== 'Y') await this.updateMoreDetail();
      if (!resultDiff && this.moreDetailYn !== 'Y' && this.spaceInfo.moreDetailYn !== 'Y') this.settingCurationValue();

      this.btnDisalbed = true;
      if (!this.curationInfo.carMood) {
        if (this.curationInfo.carPurpose.length > 1) {
          const sort = this.curationInfo.carPurpose.sort((a, b) => a - b);
          this.curationInfo.carMood = this.$store.getters['player/getCarMoodFilter'](parseInt(sort[0]));
        } else {
          this.curationInfo.carMood = this.$store.getters['player/getCarMoodFilter'](
            parseInt(this.curationInfo.carPurpose[0])
          );
        }
      }

      const carMood = this.curationInfo.carMood;
      const carPurpose = this.curationInfo.carPurpose
        .sort((a, b) => {
          return a - b;
        })
        .toString();
      let domesticRate = this.curationInfo.domesticRate;
      if (parseInt(domesticRate) < 0) domesticRate = '0';
      const genre = this.curationInfo.genre
        .sort((a, b) => {
          return a - b;
        })
        .toString();
      if (genre === '10') domesticRate = '80';
      let period = this.curationInfo.period
        .sort((a, b) => {
          return a - b;
        })
        .toString();
      if (genre === '13') period = [1, 2, 3, 4, 5].toString();
      const emotion =
        this.curationInfo.emotion.length > 0
          ? this.curationInfo.emotion
            .sort((a, b) => {
              return a - b;
            })
            .toString()
          : null;
      const spaceId = localStorage.getItem('spaceId');
      const foreignRate = String(100 - parseInt(domesticRate));
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };

      const curationInfo = {
        spaceId,
        carMood,
        carPurpose,
        domesticRate,
        foreignRate,
        genre,
        period,
        emotion
      };
      try {
        const { data: curationTempResult } = await this.updateTempCuration(curationInfo, headers);
        const { resultCd: curationTempResultCd } = curationTempResult;

        if (curationTempResultCd === '0000') {
          localStorage.setItem('spaceId', spaceId);
          const { data: updateCurationData } = await this.updateConfirmCuration(spaceId, headers);
          const { resultCd: updateCd } = updateCurationData;
          if (updateCd === '0000') {
            // Blur Loading
            const confirmResult = await this.setConfirmMusic();
            if (confirmResult) {
              this.goPlayer();
            }
            // this.$router.push({ name: "MySpaceUpdateStore5", params: { status: "updateCurationMyspace", genre } }).catch(() => { });
          } else {
            this.failUpdateCuration();
          }
        }
      } catch (error) {
        console.error('updateCuration error : ', error);
        this.failUpdateCuration();
      } finally {
        this.btnDisalbed = false;
      }
    },
    failUpdateCuration () {
      alert('선호음악 선정에 실패하였습니다.');
    },
    /**
     * @description 큐레이션 정보 임시저장
     * @param {*} curationInfo 큐레이션정보
     * @param {*} headers http 헤더
     * @author CHOI DAE GEON
     */
    async updateTempCuration (curationInfo, headers) {
      return await axios.post('/api/profile/updateCurationTemp', curationInfo, { headers });
    },
    /**
     * @description 큐레이션 정보확정
     * @param {*} spaceId 스페이스ID
     * @param {*} headers http 헤더
     * @author CHOI DAE GEON
     */
    async updateConfirmCuration (spaceId, headers) {
      return await axios.post('/api/profile/updateCuration', { spaceId }, { headers });
    },
    /**
     * @description 음원 랜덤 확정, 큐레이션 확정
     */
    async setConfirmMusic () {
      try {
        const spaceId = localStorage.getItem('spaceId');
        const noticeTypeDetail = this.$cookies.get('userId');
        const aToken = this.$cookies.get('aToken');
        const headers = { 'X-AUTH-TOKEN': aToken };

        const { data: preferMusicData } = await postRandomPreferMusic(spaceId);
        const { resultCd: preferMusicResult } = preferMusicData;
        if (preferMusicResult === '0000') {
          const { data: noticeTypeData } = await updateCurationNotice(spaceId, noticeTypeDetail, headers);
          const { resultCd: noticeTypeResultCd } = noticeTypeData;
          if (noticeTypeResultCd === '0000') {
            //
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    },
    clickPre () {
      history.back();
    },
    checkAuth () {
      const email = VueCookies.get('email');
      const url = window.location.href;
      const uri = url.indexOf('localhost') > 0 || url.lastIndexOf('dev') > 0 ? 'dev' : 'prd';

      if (this.authorizedMail.indexOf(email) > -1 && uri === 'prd') {
        this.auth = true;
      } else if (uri === 'dev') {
        this.auth = true;
      }
    },
    async goPlayer () {
      const userId = this.$cookies.get('userId');
      const spaceId = this.$cookies.get('spaceId');
      const channelId = this.$store.state.channelId;
      const carPurPose = this.curationInfo.carPurpose[0];
      const find = this.$store.getters['curation/getCarPurpose'].find(item => item.value === parseInt(carPurPose));

      // 채널정보 클리어
      if (channelId !== '') {
        await leaveChannel(userId, spaceId, channelId);
        this.$store.commit('setChannelId', '');
        this.$store.commit('setChannelUrl', null);
      }

      this.$cookies.set('spaceId', localStorage.getItem('spaceId'));
      this.$store.commit('setSpaceNm', this.spaceInfo.spaceNm);
      this.$store.commit('setSpaceId', '');
      this.$store.commit('setMute', false);
      this.$VideoPlayer.onPause();

      const routerParams = {
        spaceState: '',
        spaceType: 'Car',
        musicUrl: '',
        setIsLoading: true
      };
      routerParams.spaceState = 'callTTS';
      routerParams.musicUrl = find.musicUrl;

      this.$router
        .push({
          name: 'PlayerView',
          params: routerParams
        })
        .catch(() => {});
    },
    async getCarPurPoseCheck () {
      const spaceId = this.$cookies.get('spaceId');
      try {
        const { data } = await getCurationInfo(spaceId);
        const { resultCd, result } = data;
        if (resultCd === '0000') {
          const { carPurposeCheck } = result;
          if (carPurposeCheck === 'Y') {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    }
  },
  async created () {
    this.checkApp();
    this.getCurationInfo();
    const returnValue = await this.getMySpaceInfo();
    const { data } = returnValue;
    const { result } = data;
    this.spaceInfo = result;
    this.moreDetailYn = result.moreDetailYn;
    this.spaceType = result.spaceType;
  },
  computed: {
    hasCarPurpose1 () {
      return this.curationInfo.carPurpose.includes('5');
    },
    hasCarPurpose2 () {
      return this.curationInfo.carPurpose.includes('6');
    },
    hasCarPurpose3 () {
      return this.curationInfo.carPurpose.includes('7');
    },
    hasCarPurpose4 () {
      return this.curationInfo.carPurpose.includes('8');
    },
    hasCarPurpose5 () {
      return this.curationInfo.carPurpose.includes('9');
    },
    hasCarPurpose6 () {
      return this.curationInfo.carPurpose.includes('10');
    },
    hasCarPurpose7 () {
      return this.curationInfo.carPurpose.includes('11');
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.resizeEvent);
  },
  watch: {
    async selectTab (val) {
      await this.$nextTick();
      if (val === 2 && this.moreDetailYn !== 'Y') {
        document.querySelector('#html').style.overflow = 'hidden';
        if (document.querySelector('#mobiledim')) {
          document.querySelector('#mobiledim').style.height = `${document.querySelector('#html').clientHeight -
            86 -
            155}px`;
        }
      } else {
        document.querySelector('#html').style.overflow = 'auto';
        if (document.querySelector('#mobiledim')) {
          document.querySelector('#mobiledim').style.height = `100%`;
        }
      }
    },
    'curationInfo.carPurpose' (val) {
      if (this.moreDetailYn !== 'Y') {
        let genreArray = [];
        let meotionArray = [];
        val.forEach(carpurposeval => {
          const item = this.$store.getters['player/getCarGenreFilter'](parseInt(carpurposeval));
          const emotionItem = this.$store.getters['player/getCarEmotionFilter'](parseInt(carpurposeval));
          genreArray = [...genreArray, ...item];
          meotionArray = [...meotionArray, ...emotionItem];
        });
        const genreSet = new Set(genreArray);
        const emotionSet = new Set(meotionArray);
        this.curationInfo.genre = Array.from(genreSet).sort((a, b) => a - b);
        this.curationInfo.emotion = Array.from(emotionSet).sort((a, b) => a - b);
      }
    }
  }
});
</script>
<style scoped src="@/assets/css/updatecuration.css"></style>
<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
