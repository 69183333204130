<template>
    <div class="dim_wrap" :id="id" @click="closeDim">
        <div class="curation_dim"></div>
        <p class="curation_dim_text">
            AI 추천 적용 큐레이션 입니다. <br>
            눌러서 변경해보세요
        </p>
    </div>
</template>
<script>
export default {
  name: 'UpdateCurationDim',
  emits: ['close-dim'],
  props: {
    id: {
      type: String,
      default: ''
    }
  },

  data () {
    return {

    };
  },

  mounted () {

  },

  methods: {
    closeDim () {
      this.$emit('close-dim');
    }
  }
};
</script>
<style scoped>
.dim_wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}

.curation_dim {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #151515;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: .8;
  padding: 10px;
}

.curation_dim_text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: var(--whitefff);
  text-align: center;
  font-weight: 500;
  line-height: 1.4;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 100%;
}

@media (max-width: 500px) {
  .dim_wrap {
    top: 155px;
  }

  .curation_dim {
    padding: 0;

  }
}
</style>
