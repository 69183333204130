<template>
    <div class="single-file-alert alert">
        <div class="single-file-box2 alert_box">
            <h3><div>🎄</div>캐롤 선택 시,<br>다른 장르 선택이 불가합니다.</h3>
            <p class="single-file-content">
                캐롤 장르를 선택하지 않으셔도,
                <br/>
                공간에 어울리는 캐롤을 포함하여 큐레이션해드립니다.
            </p>
            <div class="single-file-check_carol">
                <p class="not_show" @click="notShowAgain()">다시 보지 않기</p>
                <p style="margin-bottom: 0px;" @click="$emit('closeModal')">닫기</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'genreSeasonAlertModal',
  methods: {
    notShowAgain () {
      localStorage.setItem('notShowSeasonModalCarol', 'Y');
      this.$emit('closeModal2');
    }
  }
};
</script>

<style scoped>
  .single-file-check_carol {
    width: 100%;
    height: 60px;
    border-top: 1px solid rgba(255, 255, 255, 0.08);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .single-file-check_carol p {
    width: 50%;
    height: 60px;
    border-top: 1px solid rgba(255, 255, 255, 0.08);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FF2D55;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
  }
  .single-file-check_carol p.not_show {
    color: #FFFFFF;
    opacity: 0.64;
  }
  @media all and (max-width: 500px) {
    .single-file-check_carol p {
      font-size: 15px !important;
      color: #FF2D55;
      font-weight: 400;
    }
  }
</style>
