<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <h3>캐롤 장르를 선택하실 경우,<br/>모든 시대의 캐롤을 재생해드립니다.</h3>
            <div class="single-file-check">
                <p style="margin-bottom: 0px;" @click="linkMySpace()">확인</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'periodAlertModalCarol',
  methods: {
    linkMySpace () {
      this.$emit('closeModal');
    }
  }
};
</script>

<style scoped>
  @media all and (max-width: 500px) {
    .single-file-check p {
      font-size: 15px !important;
      color: #FF2D55;
      font-weight: 400;
    }
  }
</style>
