<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <h3>재즈 노래만 선호하시는 경우,<br /> 1980s 이외의 시대도 선택해주세요.</h3>
            <!-- <p class="single-file-content">
        국내 R&B/Soul의 노래만 선호하시는 경우,<br/> 1980's 이외의 시대도 선택해주세요.
      </p> -->
            <div class="single-file-check">
                <p style="margin-bottom: 0px;" @click="linkMySpace()">확인</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'PeriodAlertModalJazz',
  methods: {
    linkMySpace () {
      this.$emit('closeModal');
    }
  }
};
</script>

<style scoped>
@media all and (max-width: 500px) {
  .single-file-check p {
    font-size: 15px !important;
    color: #FF2D55;
    font-weight: 400;
  }
}
</style>
